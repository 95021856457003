
import { Vue, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import FactorAiBase from "@/components/mixins/FactorAiBase";
import Factors from "@/store/modules/Factors";
import Decisions from "@/store/modules/Decisions";
import { createFactorObj } from "@/helpers/FactorAiHelp";
import FlashNotifications from "@/store/modules/FlashNotifications";
import { FactorAI, Factor, Task } from "@/graphql/API";
import {
    OpenAiOptionsResult,
    OpenAiResult,
    FactorExtractResults,
} from "@/graphql/custom";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import AiPrompt from "@/components/model/Ai/AiPrompt.vue";
import RecursiveAiFactors from "@/components/model/Ai/RecursiceAiFactors.vue";
import AiFactorEditor from "@/components/model/Ai/AiFactorEditor.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import FactorToggleGroup from "@/components/model/FactorToggle/FactorToggleGroup.vue";
import AiFactorCreator from "@/components/model/Ai/AiFactorCreator.vue";
import AccessDenied from "@/components/users/AccessDenied.vue";
import FactorDocumentsUploader from "@/components/ui/FactorDocumentsUploader.vue";

const modelModule = getModule(Factors);
const decisionModule = getModule(Decisions);
const flashNotificationsModule = getModule(FlashNotifications);

@Component({
    name: "FactorDocumentsView",
    components: {
        ViewWrapper,
        AiPrompt,
        AiFactorEditor,
        RecursiveAiFactors,
        VpDialog,
        FactorToggleGroup,
        AiFactorCreator,
        AccessDenied,
        FactorDocumentsUploader,
    },
})
export default class FactorDocumentsView extends mixins(FactorAiBase) {
    /* Setting this to true will return
        hard coded factors instead of Chat GPT call */
    private testing = false;

    private sideTrigger = "";
    private loading = false;
    private promptLoading = false;
    private sideIndex = "prompt";
    private mode = "edit";

    private catPromptOpen = false;
    private deleteOpen = false;
    private resetOpen = false;
    private errorOpen = false;
    private confirmOpen = false;
    private factorSelectorOpen = false;

    private selectedIds: number[] = [];

    private showHeader = true;
    private actionsMenu = false;

    private toggledFactors: number[] = [];

    private editMode = true;
    private fileUploaded = false;
    private factorsCreated = false;
    private pdfId = "";

    private extractedFactorsTree: FactorExtractResults = {
        name: "",
        description: "",
        type: "",
        sub_factors: [],
    };

    private testJson = {
        name: "Constructing a building near a creek",
        description:
            "Decision to construct a building in close proximity to a creek",
        type: "Group",
        sub_factors: [
            {
                name: "Environmental Impact",
                description:
                    "Assessment of the potential impact on the environment",
                type: "Group",
                sub_factors: [
                    {
                        name: "Water Quality",
                        description:
                            "Quality of water in the creek and potential contamination risks",
                        type: "Multiple Choice",
                    },
                    {
                        name: "Erosion Risk",
                        description:
                            "Likelihood of erosion near the creek due to construction activities",
                        type: "Multiple Choice",
                        options: [
                            "Moderate Change",
                            "Partial Block",
                            "Marked Impediment",
                        ],
                    },
                ],
            },
            {
                name: "Regulatory Compliance",
                description: "Compliance with local regulations and permits",
                type: "Group",
                sub_factors: [
                    {
                        name: "Building Codes",
                        description:
                            "Adherence to building codes and zoning laws in the area",
                        type: "Document",
                    },
                    {
                        name: "Permitting Process",
                        description:
                            "Understanding and obtaining necessary permits for construction",
                        type: "Text",
                    },
                ],
            },
            {
                name: "Safety Concerns",
                description:
                    "Evaluation of safety risks associated with building near a creek",
                type: "Group",
                sub_factors: [
                    {
                        name: "Flood Risk",
                        description:
                            "Potential for flooding and measures to mitigate risks",
                        type: "Multiple Choice",
                        options: [
                            "No Impact",
                            "Minor Change",
                            "Moderate Change",
                            "Partial Block",
                            "Marked Impediment",
                        ],
                    },
                    {
                        name: "Wildlife Interaction",
                        description:
                            "Potential interactions with wildlife in the creek area",
                        type: "Multiple Choice",
                    },
                ],
            },
        ],
    };

    private catFactors: {
        factor: Factor;
        options: boolean;
        group: boolean;
        boolean: boolean;
    }[] = [];

    private setPdfId(id: string) {
        this.fileUploaded = true;
        this.pdfId = id;
    }

    get hasFactors(): boolean {
        if (this.factorTree[-1] && this.factorTree[-1].length) {
            return true;
        } else {
            return false;
        }
    }

    get selectedId(): number | null {
        if (this.selectedIds.length) {
            return this.selectedIds[0];
        } else {
            return null;
        }
    }

    get selectedFactors(): Factor[] {
        if (this.selectedIds.length) {
            return this.selectedIds.map((id) => this.factorMap[id]);
        } else {
            return [];
        }
    }

    get selectedFactor(): Factor | null {
        if (this.selectedFactors.length) {
            return this.selectedFactors[0];
        } else {
            return null;
        }
    }

    get loadingOverlay(): boolean {
        return this.loading || this.promptLoading;
    }

    get addDisabled(): boolean {
        if (this.selectedGroup && this.selectedGroup.length) {
            return false;
        } else {
            return true;
        }
    }

    get decisionName(): string {
        const decision = decisionModule.selectedDecision;
        return decision?.name ?? "Making decision";
    }

    private async generateFactors(): Promise<void> {
        this.loading = true;

        if (this.testing) {
            this.$emit("generated", {
                factors: this.testJson,
                // parent: this.parent ? this.parent.id : -1,
            });
        } else {
        }
        /*
         */
        this.loading = false;
    }

    private async extractFactorsFromDocument() {
        try {
            this.loading = true;
            let extractFactorsResults = await modelModule.extractFactors({
                decision_name: this.decisionName,
                pdf_id: [this.pdfId],
            });
            const results = JSON.parse(extractFactorsResults);
            if (results.success) {
                if (results["factor_tree"]) {
                    let factorTreeJsonString = results["factor_tree"]
                        .replace(/```json\n?|\n?```/g, "")
                        .trim(); // Extract JSON string
                    try {
                        const factorTreeObject =
                            JSON.parse(factorTreeJsonString);
                        this.extractedFactorsTree = factorTreeObject;
                        this.extractedFactors();
                        this.loading = false;
                    } catch (error) {
                        this.loading = false;
                        console.error("Error parsing factor_tree JSON:", error);
                    }
                } else {
                    this.loading = false;
                    console.error("factor_tree not found in response");
                }
            } else {
                this.loading = false;
                console.error(
                    "Error parsing factor_tree JSON:",
                    results.message
                );
                flashNotificationsModule.error({
                    message: results.message,
                    duration: 5000,
                });
            }

            // test
            // this.extractedFactorsTree = this.testJson;
            // this.extractedFactors();
            // this.loading = false;
        } catch (error) {
            flashNotificationsModule.error({
                message: `Unexpected error, system cannot read this file`,
                duration: 5000,
            });
            this.loading = false;
        }
    }

    private async extractedFactors(): Promise<void> {
        const listFactors = this.flattenFactorsObject(
            this.extractedFactorsTree
        );
        const generatedFactors = listFactors || []; // Assuming `sub_factors` is part of `testJson`
        const ids = await this.getRandomFactorsIds(
            generatedFactors.length,
            this.factorIds
        );

        // Now, use the random IDs to add them to the objects
        await this.addIdsToObject(this.extractedFactorsTree, ids, -1, 0);

        // After adding IDs, flatten the decision object
        let flattenedDecisionObject = this.flattenFactorsObject(
            this.extractedFactorsTree
        );

        const factors = await this.getExtractedFactors(
            flattenedDecisionObject.map((factor) => {
                return {
                    id: factor.id ? factor.id.toString() : "0",
                    name: factor.name,
                    description: factor.description,
                    type: factor.type,
                    parent_id: factor.parent_id,
                };
            })
        );

        if (factors.length > 0) {
            this.setFactorTree(factors);
            if (this.extractedOptions) {
                this.populateExtracteCatOptions(factors, this.extractedOptions);
            } else {
                this.toggleCatPrompt(factors);
            }
        } else {
            this.errorOpen = true;
            setTimeout(() => {
                this.errorOpen = false;
            }, 3000);
        }
    }

    private async addIdsToObject(
        obj: FactorExtractResults, // Assuming `obj` is of type `FactorExtractResults`
        ids: number[],
        parentId = -1, // Default parentId for root object
        index = 0 // Starting index
    ): Promise<number> {
        // If we're out of ids to assign, return the index
        if (index >= ids.length) {
            return index;
        }

        // Assign the current id from the ids array
        obj.id = ids[index];
        obj.parent_id = parentId; // Assign the parent_id to the current object

        if(obj.id && obj.options && obj.options.length > 0) {
            let optionsObj = {
                category_name: obj.name,
                options: obj.options
            }
            this.extractedOptions.push(optionsObj);
        }

        // If the object has sub_factors, recursively assign ids to them
        if (obj.sub_factors && Array.isArray(obj.sub_factors)) {
            for (let i = 0; i < obj.sub_factors.length; i++) {
                const subFactor = obj.sub_factors[i];
                subFactor.parent_id = obj.id; // Ensure parent_id is the current object's id
                index = await this.addIdsToObject(
                    subFactor,
                    ids,
                    obj.id,
                    index + 1
                );
            }
        }
        // Return the next index for the next object in the hierarchy
        return index;
    }

    // Function to flatten the decision object into a list
    private flattenFactorsObject(
        obj: FactorExtractResults
    ): FactorExtractResults[] {
        let result: FactorExtractResults[] = [];

        // Add the current object to the result
        result.push({
            name: obj.name,
            id: obj.id,
            parent_id: obj.parent_id,
            description: obj.description,
            type: obj.type,
        });

        // If the object has sub_factors, recursively process them
        if (obj.sub_factors && Array.isArray(obj.sub_factors)) {
            obj.sub_factors.forEach((subFactor: FactorExtractResults) => {
                result = result.concat(this.flattenFactorsObject(subFactor)); // Concatenate the flattened sub_factors
            });
        }

        return result;
    }

    // Function to generate random IDs
    private async getRandomFactorsIds(
        length: number,
        ids: number[]
    ): Promise<number[]> {
        let newIds: number[] = [];
        for (let i = 0; i < length; i++) {
            let id = 0;
            while (true) {
                id = Math.floor(Math.random() * 1000000); // Generate a random ID
                if (!ids.includes(id) && !newIds.includes(id) && id !== 0) {
                    break;
                }
            }
            newIds.push(id);
        }
        return newIds;
    }

    private async getExtractedFactors(
        generatedFactors: FactorAI[]
    ): Promise<Factor[]> {
        return await Promise.all(
            generatedFactors.map(async (factor, index) => {
                let parent_id = factor.parent_id ? factor.parent_id : -1;
                return await createFactorObj(
                    factor,
                    parent_id,
                    this.selectedDecisionId ? this.selectedDecisionId : -1,
                    Number(factor.id),
                    `${index}`,
                    this.defaultFactorSetId
                );
            })
        );
    }

    /* GeneratedFactors is actually type OpenAiResult[] when emited to this component */
    private async populateFactorsTable(payload: {
        factors: OpenAiResult[];
        parent?: number;
    }): Promise<void> {
        const factors = await this.getGeneratedFactors(
            payload.factors.map((factor) => {
                return {
                    id: factor.factor_id.toString(),
                    name: factor.name,
                    description: factor.description,
                    type: factor.type,
                    parent_id: factor.parent_id,
                };
            }),
            payload.parent
        );
        if (factors.length > 0) {
            this.setFactorTree(factors);
            this.toggleCatPrompt(factors);
        } else {
            this.errorOpen = true;
            setTimeout(() => {
                this.errorOpen = false;
            }, 3000);
        }
    }

    private toggleCatPrompt(factors: Factor[]): void {
        this.catFactors = factors
            .filter(
                (factor) =>
                    factor.value_type == "category" || factor.type == "category"
            )
            .map((factor) => {
                return {
                    factor: factor,
                    options: true,
                    group: false,
                    boolean: false,
                };
            });
        if (this.catFactors.length > 0) {
            this.catPromptOpen = true;
        }
    }

    private setFactorTree(factors: Factor[]): void {
        factors.forEach((factor) => {
            const parentId = factor.parent_id != null ? factor.parent_id : -1;
            Vue.set(this.factorMap, factor.id, factor);
            if (this.factorTree[parentId]) {
                this.factorTree[parentId].push(factor);
            } else {
                Vue.set(this.factorTree, parentId, [factor]);
            }
        });
    }

    private async getGeneratedFactors(
        generatedFactors: FactorAI[],
        parentId = -1
    ): Promise<Factor[]> {
        const ids = await this.getRandomIds(
            generatedFactors.length,
            this.factorIds
        );

        return await Promise.all(
            generatedFactors.map(async (factor, index) => {
                return await createFactorObj(
                    factor,
                    parentId,
                    this.selectedDecisionId ? this.selectedDecisionId : -1,
                    ids[index],
                    `${index}`,
                    this.defaultFactorSetId
                );
            })
        );
    }

    private async getRandomIds(
        length: number,
        ids: number[]
    ): Promise<number[]> {
        let newIds: number[] = [];
        for (let i = 0; i < length; i++) {
            let id = 0;
            for (;;) {
                id = Math.floor(Math.random() * 1000000);
                if (!ids.includes(id) && !newIds.includes(id) && id != 0) {
                    break;
                }
            }
            newIds.push(id);
        }
        return newIds;
    }

    private async initGenerateOptions(factor: Factor): Promise<void> {
        if (factor) {
            this.loading = true;
            await this.generateOptions([factor]);
            this.loading = false;
        }
    }

    private async generateConvert(): Promise<void> {
        this.loading = true;
        this.catPromptOpen = false;
        await Promise.all([
            this.convertToGroup(
                this.catFactors
                    .filter((item) => item.group)
                    .map((item) => item.factor)
            ),
            this.generateOptions(
                this.catFactors
                    .filter((item) => item.options)
                    .map((item) => item.factor)
            ),
            this.generateBoolean(
                this.catFactors
                    .filter((item) => item.boolean)
                    .map((item) => item.factor)
            ),
        ]);
        this.loading = false;
    }

    private async convertSingleGroup(factors: Factor[]): Promise<void> {
        this.loading = true;
        await this.convertToGroup(factors);
        this.loading = false;
    }

    /* Function for Chris to use to create groups prompt */
    private async convertToGroup(factors: Factor[]): Promise<void> {
        const factorGroupsInput: OpenAiResult[] = [];
        const categoryFactors = [
            ...factors.filter(
                (factor) =>
                    factor.value_type === "category" &&
                    this.optionTree[factor.id] != null &&
                    this.optionTree[factor.id].length > 0
            ),
        ];
        const nonCatFactors = [
            ...factors.filter(
                (factor) =>
                    factor.value_type != "category" ||
                    this.optionTree[factor.id] == null ||
                    this.optionTree[factor.id].length === 0
            ),
        ];
        /* For CatFactors with enums make options factors in group */
        if (categoryFactors.length > 0) {
            await this.doCategoricalToGroupConvert(factors);
        }
        /* For nonCatFactors use secondlevelprompt endpoint */
        if (nonCatFactors.length > 0) {
            await Promise.all(
                factors.map(async (factor) => {
                    this.changeToGroup(this.factorMap[factor.id]);
                    factorGroupsInput.push({
                        task_id: "10000",
                        factor_id: factor.id,
                        name: factor.name,
                        description: factor.description
                            ? factor.description
                            : "",
                        type: factor.value_type,
                    });
                })
            );
            const res = await modelModule.getGeneratedSecondLevelFactors({
                groups: factorGroupsInput,
                topic_area: this.topicArea,
                creativity: this.creativity,
                persona: this.persona,
            });
            await this.getGeneratedFactorsResult(res, 0);
        }
    }

    private async doCategoricalToGroupConvert(
        factors: Factor[]
    ): Promise<void> {
        const optionsToFactors: OpenAiResult[] = [];
        factors.map((factor) => {
            this.changeToGroup(factor);
            const options = this.optionTree[factor.id];
            const optionsFacs: OpenAiResult[] = options.map(
                (category, index) => {
                    return {
                        factor_id: index,
                        name: category,
                        type: "categorical",
                        description: category,
                        parent_id: factor.id,
                        task_id: "1000",
                    };
                }
            );
            optionsToFactors.splice(0, 0, ...optionsFacs);
            Vue.delete(this.optionTree, factor.id);
        });
        this.populateFactorsTable({ factors: optionsToFactors });
    }

    private convertToMultipleChoice(factors: Factor[]): void {
        const catOptions: OpenAiOptionsResult[] = [];
        for (const factor in factors) {
            const multipleChoices = this.factorTree[factors[factor].id].map(
                (child) => child.name
            );
            const catVal: OpenAiOptionsResult = {
                cat_id: parseInt(factor),
                task_id: "",
                category_name: factors[factor].name,
                options: JSON.stringify(multipleChoices),
            };
            catOptions.push(catVal);
        }
        this.populateCatOptions(factors, catOptions);
        factors.map((factor) => this.changeToMultipleChoice(factor));
    }

    private async getGeneratedFactorsResult(
        task: Task | null,
        requests: number
    ): Promise<OpenAiResult[] | null | undefined> {
        if (task) {
            try {
                let results = await modelModule.getGeneratedFactorsResults({
                    task_id: task.id,
                });
                if (results) {
                    let taskStatus = results.task.status;
                    if (taskStatus == "SUCCESS") {
                        if (results.result)
                            this.populateFactorsTable({
                                factors: results.result,
                            });
                    } else if (taskStatus == "PENDING" && requests <= 6) {
                        /*
                            If pending then returns a promise of a timeout that waits
                            and calls the function again with requests + 1

                            Promise required an undefined type
                        */
                        return await new Promise<
                            OpenAiResult[] | null | undefined
                        >((resolve) => {
                            setTimeout(() => {
                                this.getGeneratedFactorsResult(
                                    task,
                                    requests + 1
                                ).then(
                                    (
                                        values:
                                            | OpenAiResult[]
                                            | null
                                            | undefined
                                    ) => {
                                        resolve(values ? values : null);
                                    }
                                );
                            }, 15000);
                        }).then((values: OpenAiResult[] | null | undefined) => {
                            return values;
                        });
                    } else if (taskStatus == "FAILURE") {
                        flashNotificationsModule.error({
                            message: `Unexpected error, task has status FAILURE`,
                            duration: 5000,
                        });
                        return null;
                    } else {
                        flashNotificationsModule.warning({
                            message: `It looks like the process is taking too long. Try one more time.`,
                            duration: 10000,
                        });
                        return null;
                    }
                }
            } catch (error) {
                flashNotificationsModule.error({
                    message: `Unexpected error: ${error}`,
                    duration: 10000,
                });
                console.log(
                    "%cError getGeneratedFactorsResult():",
                    "color: red; font-weight: bold;"
                );
                console.log(error);
                return null;
            }
        }
    }

    private async generateOptions(factors: Factor[]): Promise<void> {
        if (factors.length > 0) {
            const categoricalFactors = JSON.stringify(
                factors.map((factor) => factor.name)
            );

            try {
                const task = await modelModule.getGeneratedCategoryOptions({
                    topic_area: this.topicArea
                        ? this.topicArea
                        : this.decisionName,
                    creativity: this.creativity ? this.creativity : 1,
                    categorical_factors: categoricalFactors,
                });
                let options: OpenAiOptionsResult[] | null | undefined = [];
                if (this.testing) {
                    options = this.testOptions;
                } else {
                    options = await this.getGeneratedCategoryOptions(task, 0);
                }

                if (options && options.length) {
                    await this.populateCatOptions(factors, options);
                }
            } catch (error) {
                flashNotificationsModule.error({
                    message: `Unexpected error: ${error}`,
                    duration: 5000,
                });
                console.log(
                    "%cError generateOptions():",
                    "color: red; font-weight: bold;"
                );
                console.log(error);
            }
        }
    }

    private async generateBoolean(factors: Factor[]): Promise<void> {
        if (factors.length > 0) {
            await Promise.all(
                factors.map(async (factor) => {
                    Vue.set(this.optionTree, factor.id, ["true", "false"]);
                })
            );
        }
    }

    private async getGeneratedCategoryOptions(
        task: Task | null,
        requests: number
    ): Promise<OpenAiOptionsResult[] | null | undefined> {
        if (task) {
            try {
                let results =
                    await modelModule.getGeneratedCategoryOptionsResults({
                        task_id: task.id,
                    });
                if (results) {
                    let taskStatus = results.task.status;
                    if (taskStatus == "SUCCESS") {
                        return results.result;
                    } else if (taskStatus == "PENDING" && requests <= 5) {
                        return await new Promise<
                            OpenAiOptionsResult[] | null | undefined
                        >((resolve) => {
                            setTimeout(() => {
                                this.getGeneratedCategoryOptions(
                                    task,
                                    requests + 1
                                ).then(
                                    (
                                        values:
                                            | OpenAiOptionsResult[]
                                            | null
                                            | undefined
                                    ) => {
                                        resolve(values);
                                    }
                                );
                            }, 5000);
                        }).then(
                            (
                                values: OpenAiOptionsResult[] | null | undefined
                            ) => {
                                return values;
                            }
                        );
                    } else if (taskStatus == "FAILURE") {
                        flashNotificationsModule.error({
                            message: `Unexpected error, task has status FAILURE`,
                            duration: 5000,
                        });
                        return null;
                    } else {
                        flashNotificationsModule.warning({
                            message: `It looks like the process is taking too long. Try one more time.`,
                            duration: 10000,
                        });
                        return null;
                    }
                } else {
                    flashNotificationsModule.warning({
                        message: `It looks like the process is taking too long. Try one more time.`,
                        duration: 10000,
                    });
                    return null;
                }
            } catch (error) {
                flashNotificationsModule.error({
                    message: `Unexpected error: ${error}`,
                    duration: 10000,
                });
                console.log(
                    "%cError getGeneratedCategoryOptions():",
                    "color: red; font-weight: bold;"
                );
                console.log(error);
                return null;
            }
        } else {
            return null;
        }
    }

    private async populateCatOptions(
        factors: Factor[],
        options: OpenAiOptionsResult[]
    ): Promise<void> {
        await Promise.all(
            options.map(async (option) => {
                const factor = factors.find(
                    (factor) => factor.name == option.category_name
                );
                if (factor && option.options) {
                    Vue.set(
                        this.optionTree,
                        factor.id,
                        JSON.parse(option.options)
                    );
                }
            })
        );
    }

    private async populateExtracteCatOptions(
        factors: Factor[],
        options: any[]
    ) {
        options.map((option) => {
            const factor = factors.find(
                (factor) => factor.name == option.category_name
            );
            if (factor && option.options) {
                Vue.set(this.optionTree, factor.id, option.options);
            }
        });
    }

    private toggleFactor(factor: Factor): void {
        this.editMode = true;
        if (factor) {
            if (this.selectedIds.includes(factor.id)) {
                this.mode = "info";
                this.sideTrigger = "sidebar-close";
                this.selectedIds.splice(this.selectedIds.indexOf(factor.id), 1);
            } else {
                this.mode = "edit";
                this.selectedIds = [factor.id];
                this.sideTrigger = `edit-${factor.id}`;
            }
        }
    }

    private closeCatPrompt(): void {
        this.catPromptOpen = false;
        this.catFactors = [];
    }

    private changeToGroup(factor: Factor): void {
        if (factor) {
            Vue.set(this.factorMap[factor.id], "value_type", "group");
            Vue.set(this.factorMap[factor.id], "is_group", true);
            Vue.set(
                this.factorMap[factor.id],
                "index_rule_id",
                this.orgIdToDefaultRuleMap[-9].id
            );
            Vue.set(
                this.factorMap[factor.id],
                "score_rule_id",
                this.orgIdToDefaultRuleMap[-8].id
            );
        }
    }

    private changeToMultipleChoice(factor: Factor): void {
        if (factor.is_group || factor.value_type === "group") {
            Vue.delete(this.factorTree, factor.id);
        }
        if (factor) {
            Vue.set(this.factorMap[factor.id], "value_type", "category");
            Vue.set(this.factorMap[factor.id], "is_group", false);
            Vue.set(
                this.factorMap[factor.id],
                "index_rule_id",
                this.orgIdToDefaultRuleMap[-4].id
            );
            Vue.set(
                this.factorMap[factor.id],
                "score_rule_id",
                this.orgIdToDefaultRuleMap[-3].id
            );
        }
    }

    private async deleteFactors(): Promise<void> {
        await Promise.all(
            this.selectedFactors.map(async (factor) => {
                this.deleteFactor(factor);
            })
        );
        this.deleteOpen = false;
    }

    private deleteFactor(factor: Factor): void {
        Vue.delete(this.factorMap, factor.id);
        if (factor.parent_id && this.factorTree[factor.parent_id]) {
            Vue.delete(
                this.factorTree[factor.parent_id],
                this.factorTree[factor.parent_id].findIndex(
                    (item) => item.id == factor.id
                )
            );
        }
    }

    private toggleOptions(val: boolean, index: number): void {
        if (val) {
            Vue.set(this.catFactors[index], "group", false);
            Vue.set(this.catFactors[index], "boolean", false);
        }
    }

    private toggleBoolean(val: boolean, index: number): void {
        if (val) {
            Vue.set(this.catFactors[index], "group", false);
            Vue.set(this.catFactors[index], "options", false);
        }
    }

    private toggleGroup(val: boolean, index: number): void {
        if (val) {
            Vue.set(this.catFactors[index], "options", false);
            Vue.set(this.catFactors[index], "boolean", false);
        }
    }

    private resetDecision(): void {
        this.creativity = 1;
        this.topicArea = "";
        this.persona = "";
        this.factorMap = {};
        this.factorTree = {};
        this.optionTree = {};
        this.catFactors = [];
        this.resetOpen = false;
        this.confirmOpen = false;
        this.selectedGroup = [];
    }

    private async addToDecision(): Promise<void> {
        if (this.rootId && this.factorTree[-1] && this.factorTree[-1].length) {
            this.loading = true;
            await this.saveFactors(this.factorTree[-1], this.rootId, true);
            this.resetDecision();
            this.loading = false;
        } else {
            flashNotificationsModule.error({
                message: this.rootId
                    ? "No factors created"
                    : "Please select a group to add to",
                duration: 5000,
            });
        }
    }

    private newFactor(): void {
        this.editMode = false;
    }

    private async createNew(payload: {
        factor: Factor;
        options: string[] | null;
    }): Promise<void> {
        const ids = await this.getRandomIds(1, this.factorIds);
        if (ids.length) {
            const id = ids[0];
            const factor = {
                ...payload.factor,
                id: id,
                order_str: `${
                    this.factorTree[payload.factor.id]
                        ? this.factorTree[payload.factor.id].length
                        : 0
                }`,
            };
            Vue.set(this.factorMap, id, factor);
            if (this.factorTree[factor.parent_id ? factor.parent_id : -1]) {
                this.factorTree[factor.parent_id ? factor.parent_id : -1].push(
                    factor
                );
            } else {
                Vue.set(
                    this.factorTree,
                    factor.parent_id ? factor.parent_id : -1,
                    [factor]
                );
            }
            if (factor.value_type == "categorical" && payload.options) {
                Vue.set(this.optionTree, id, payload.options);
            }
        }
    }
}
