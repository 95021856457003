
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Comment } from "@/graphql/API";
import { getModule } from "vuex-module-decorators";
import Comments from "@/store/modules/Comments";
import CommentEditor from "@/components/comments/tab/panel/CommentEditor.vue";

const commentModule = getModule(Comments);

@Component({
    name: "CommentBlock",
    components: {
        CommentEditor,
    },
})
export default class CommentBlock extends Vue {
    @Prop()
    comment!: Comment;

    @Prop({ default: true, type: Boolean })
    showReplies!: boolean;

    @Prop({ default: false, type: Boolean })
    showEdit!: boolean;

    @Prop()
    replyActive!: number | null;

    private editActive = false;
    private loading = false;
    private showMenu = false;

    private refresh = "";
    private replyLoading = false;

    private expanded = false;

    get repliesNum(): number {
        if (this.comment && commentModule.keyedReplies[this.comment.id]) {
            return commentModule.keyedReplies[this.comment.id].length;
        } else {
            return 0;
        }
    }

    get showReplyEditor(): boolean {
        if (this.comment && this.replyActive) {
            return this.comment.id == this.replyActive;
        } else {
            return false;
        }
    }

    get date(): string {
        if (this.comment && this.comment.created) {
            const d = new Date(this.comment.created * 1000);
            return d.toDateString();
        } else {
            return "";
        }
    }

    private editComment(): void {
        this.editActive = true;
    }

    private refreshEditor(): void {
        const currentDate = new Date();
        this.refresh = currentDate.toString();
    }

    private async saveReply(val: string): Promise<void> {
        if (this.comment) {
            this.replyLoading = true;
            commentModule.createReply({
                reply_to: this.comment.id,
                comment: val,
            });
            /* Clear */
            this.refreshEditor();
            this.replyLoading = false;
        }
    }

    private async updateComment(val: string): Promise<void> {
        if (this.comment) {
            this.loading = true;
            await commentModule.updateComment({
                id: this.comment.id,
                comment: val,
            });
            this.editActive = false;
            this.loading = false;
        }
    }

    private async deleteComment(): Promise<void> {
        if (this.comment) {
            this.loading = true;
            await commentModule.deleteComment(this.comment.id);
            this.loading = false;
        }
    }

    @Watch("showEdit", { immediate: true })
    private checkEditMode(): void {
        if (this.showEdit) {
            this.editActive = true;
        }
    }
}
