
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import { saveTab } from "@/helpers/TabHelper";
import Factors from "@/store/modules/Factors";
import RecursiveModelFactors from "@/components/model/RecursiveModel.vue";
import FactorCreator from "@/components/model/FactorCreator.vue";
import { Factor, ModelFilters, Tab } from "@/graphql/API";
import { getNextOrderStrs, createFactorL } from "@/helpers/FactorHelper";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import ModelEditor from "@/components/model/ModelEditor.vue";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import Decisions from "@/store/modules/Decisions";
import VpDialog from "@/components/ui/VpDialog.vue";
import RecursiveModelGroup from "@/components/model/RecursiveModelGroup.vue";
import PermissionCheck from "@/components/mixins/PermissionCheck";
import AccessDenied from "@/components/users/AccessDenied.vue";
import FactorToggleGroup from "@/components/model/FactorToggle/FactorToggleGroup.vue";
import ViewAppCreator from "@/components/apps/ViewAppCreator.vue";

const modelModule = getModule(Factors);
const decisionsModule = getModule(Decisions);

@Component({
    components: {
        RecursiveModelFactors,
        FactorCreator,
        ViewWrapper,
        ModelEditor,
        DialogCardTitle,
        VpDialog,
        RecursiveModelGroup,
        AccessDenied,
        FactorToggleGroup,
        ViewAppCreator,
    },
})
export default class ModelView extends mixins(PermissionCheck) {
    @Prop({ default: false, type: Boolean })
    isAdmin!: boolean;

    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop()
    header!: string;

    @Prop()
    bannerImage!: string;

    @Prop()
    bannerAlign!: string;

    @Prop({ default: "ModelView", type: String })
    view!: string;

    @Prop()
    tabSettings!: Tab;

    private selectedRootId = -1;
    private toggledFactors: number[] = [];
    private appCreateOpen = false;
    private loading = false;

    private sideBarActive = false;
    private createNew = true;
    private deleteDialog = false;
    private actionsMenu = false;
    private sideTrigger = "";
    private sideTriggerMobile = "";
    private multiSelect = false;
    private backFactor: Factor | null = null;
    private pageLoading = false;

    private drag = false;
    private loadIds: number[] = [];

    private selectedIds: number[] = [];
    private removeGroupFactorsIds: number[] = [];
    private removeTableFactorsIds: number[] = [];
    private removeColumnFactorsIds: number[] = [];
    private removeFactorsIds: number[] = [];
    private filters: ModelFilters = {
        type: [],
        search: "",
    };
    private filterType = "";
    private filterSearch = "";

    private open = {};

    get decisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get currentSettings(): Tab {
        if (this.tabSettings) {
            return {
                ...this.tabSettings,
                json: "",
                edit_flags: "",
                display_flags: "",
                filter_type: "factor",
                row_type: "factor",
                column_type: "factor",
                root_factor_id: this.selectedRootId,
            };
        } else {
            return {
                id: -1,
                decision_id: this.decisionId ? this.decisionId : -1,
                title: "Factors",
                json: "",
                edit_flags: "",
                display_flags: "",
                filter_type: "factor",
                row_type: "factor",
                column_type: "factor",
                root_factor_id: this.selectedRootId,
                type: "ModelView",
            };
        }
    }

    get viewAccess(): boolean {
        if (this.view == "ModelView") {
            return this.factorRead;
        } else {
            return this.factorWrite;
        }
    }

    get editModel(): boolean {
        return this.factorWrite;
    }

    get rootLoading(): boolean {
        return modelModule.rootLoading;
    }

    get factors(): Factor[] {
        return modelModule.factors;
    }

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get selectedFactorsKeys(): string[] {
        return Object.keys(this.selectedFactors);
    }

    get selectedDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get types(): { text: string; id: string }[] {
        return modelModule.valueTypes;
    }

    get selectedFactors(): Factor[] {
        return this.selectedIds.map((id) => {
            return modelModule.factorMap[id];
        });
    }

    get tabRootId(): number {
        if (this.tabSettings && this.tabSettings.root_factor_id) {
            return this.tabSettings.root_factor_id;
        } else {
            return -1;
        }
    }

    get editFactorId(): number | null {
        if (this.selectedIds.length == 1) {
            return this.selectedIds[0];
        } else {
            return null;
        }
    }

    get frameworkRoot(): Factor | undefined {
        return modelModule.factorRoot;
    }

    get lockedGroupId(): number {
        if (this.selectedRootId != -1) {
            return this.selectedRootId;
        } else {
            return this.frameworkRoot ? this.frameworkRoot.id : -1;
        }
    }

    private toggleFactor(event: {
        item: Factor;
        multiple: boolean;
        backFactor?: Factor;
        mobile: boolean;
    }) {
        this.createNew = false;
        this.backFactor = null;
        if (this.selectedIds.includes(event.item.id) && !event.mobile) {
            // Factor has been already been selected
            if (event.multiple || this.multiSelect) {
                this.selectedIds.splice(
                    this.selectedIds.indexOf(event.item.id),
                    1
                );
                if (this.removeGroupFactorsIds.includes(event.item.id)) {
                    this.removeGroupFactorsIds.splice(
                        this.removeGroupFactorsIds.indexOf(event.item.id),
                        1
                    );
                } else if (this.removeTableFactorsIds.includes(event.item.id)) {
                    this.removeTableFactorsIds.splice(
                        this.removeTableFactorsIds.indexOf(event.item.id),
                        1
                    );
                } else if (
                    this.removeColumnFactorsIds.includes(event.item.id)
                ) {
                    this.removeColumnFactorsIds.splice(
                        this.removeColumnFactorsIds.indexOf(event.item.id),
                        1
                    );
                } else {
                    this.removeFactorsIds.splice(
                        this.removeFactorsIds.indexOf(event.item.id),
                        1
                    );
                }
            } else {
                this.selectedIds = [];
                this.removeGroupFactorsIds = [];
                this.removeTableFactorsIds = [];
                this.removeColumnFactorsIds = [];
                this.removeFactorsIds = [];
                this.sideTrigger = "sidebar-close";
            }
        } else {
            if (event.multiple || this.multiSelect) {
                this.selectedIds.push(event.item.id);
                if (event.item.is_group) {
                    this.removeGroupFactorsIds.push(event.item.id);
                } else if (event.item.is_table) {
                    this.removeTableFactorsIds.push(event.item.id);
                } else if (event.item.is_column) {
                    this.removeColumnFactorsIds.push(event.item.id);
                } else {
                    this.removeFactorsIds.push(event.item.id);
                }
            } else {
                this.selectedIds = [event.item.id];
                this.sideTrigger = event.item.id.toString();

                if (event.item.is_group) {
                    this.removeGroupFactorsIds = [event.item.id];
                    //remove rest of factor types ids
                    this.removeTableFactorsIds = [];
                    this.removeColumnFactorsIds = [];
                    this.removeFactorsIds = [];
                } else if (event.item.is_table) {
                    this.removeTableFactorsIds = [event.item.id];
                    //remove rest of factor types ids
                    this.removeGroupFactorsIds = [];
                    this.removeColumnFactorsIds = [];
                    this.removeFactorsIds = [];
                } else if (event.item.is_column) {
                    this.removeColumnFactorsIds = [event.item.id];
                    //remove rest of factor types ids
                    this.removeTableFactorsIds = [];
                    this.removeGroupFactorsIds = [];
                    this.removeFactorsIds = [];
                } else {
                    this.removeFactorsIds = [event.item.id];
                    //remove rest of factor types ids
                    this.removeColumnFactorsIds = [];
                    this.removeTableFactorsIds = [];
                    this.removeGroupFactorsIds = [];
                }

                if (event.mobile) {
                    // Random number added so user can click the button to bring up the sidebar when it's full width
                    this.sideTriggerMobile =
                        event.item.id.toString() + Math.random().toString();
                } else {
                    this.sideTrigger = event.item.id.toString();
                }
                this.backFactor = event.backFactor ? event.backFactor : null;
            }
        }
    }

    private showCreateScreen() {
        this.createNew = true;
        this.sideTrigger = `new-factor-panel-${Math.random().toString()}`;
        this.sideTriggerMobile = `new-factor-panel-${Math.random().toString()}`;
    }

    private async deleteFactors() {
        this.selectedIds = [];
        if (this.removeGroupFactorsIds.length > 0) {
            await modelModule.deleteGroupL(this.removeGroupFactorsIds);
        }
        if (this.removeTableFactorsIds.length > 0) {
            await modelModule.deleteTableL(this.removeTableFactorsIds);
        }
        if (this.removeColumnFactorsIds.length > 0) {
            await modelModule.deleteColumnL(this.removeColumnFactorsIds);
        }
        if (this.removeFactorsIds.length > 0) {
            await modelModule.deleteFactorL(this.removeFactorsIds);
        }

        this.removeGroupFactorsIds = [];
        this.removeTableFactorsIds = [];
        this.removeColumnFactorsIds = [];
        this.removeFactorsIds = [];
        this.deleteDialog = false;
    }

    private async copySelectedFactors(): Promise<void> {
        await Promise.all(
            this.selectedFactors.map(async (factor) => {
                await this.copySingleFactor(factor, true, factor.parent_id);
            })
        );
    }

    private async copySingleFactor(
        factor: Factor,
        sameGroup: boolean,
        parentId?: number
    ): Promise<void> {
        const newId = await this.copyFactor(
            factor,
            parentId ? parentId : -1,
            sameGroup,
            sameGroup
                ? getNextOrderStrs(
                      this.factorTree[
                          factor.parent_id != null ? factor.parent_id : -1
                      ],
                      1
                  )[0]
                : factor.order_str
        );

        if ((factor.is_group || factor.is_table) && newId != null) {
            await this.recursiveCopyFactors(factor, newId);
        }
    }

    private async recursiveCopyFactors(
        factor: Factor,
        newParentId: number
    ): Promise<void> {
        if (!this.factorTree[factor.id]) {
            await modelModule.fetchChildFactors(factor.id);
        }
        this.factorTree[factor.id].map(async (item) => {
            this.copySingleFactor(item, false, newParentId);
        });
    }

    private async copyFactor(
        factor: Factor,
        parentId: number,
        sameGroup: boolean,
        order: string
    ): Promise<number | null> {
        let factorType = factor.value_type;
        let enumId: number | undefined;
        if (factor.is_group) {
            factorType = "group";
        } else if (factor.is_table) {
            factorType = "table";
        }
        if (factor.value_type == "category" && factor.enum_id)
            enumId = await this.copyEnumsAndValues(factor.enum_id);

        let newFactor = await createFactorL({
            names: [`${factor.name}${sameGroup ? " copy" : ""}`],
            order_str: [order],
            valueType: factorType,
            parentId: parentId,
            isColumn: factor.is_column,
            options: factor.json != null ? factor.json : "",
            format: factor.format,
            useManualScore: factor.m_score,
            useManualIndex: factor.m_index,
            score_rule_id: factor.score_rule_id ? factor.score_rule_id : -2,
            index_rule_id: factor.index_rule_id ? factor.index_rule_id : -3,
            set_id: factor.set_id ? factor.set_id : -1,
            weight: factor.weight,
            description: factor.description,
            enum_ids: enumId ? [enumId] : undefined,
            unit_id: factor.unit_id,
        });

        return newFactor != null && newFactor.length
            ? newFactor[newFactor.length - 1].id
            : null;
    }

    private async copyEnumsAndValues(
        originalEmunId: number
    ): Promise<number | undefined> {
        let enumId: number | undefined;
        let enumObject = modelModule.enums[originalEmunId];
        let newEnumValues = enumObject.values.map((value) => value.value);
        let newFactorEnum = await modelModule.createEnums({
            decision_id: enumObject.decision_id,
            names: [enumObject.name],
        });

        enumId = newFactorEnum ? newFactorEnum[0].id : undefined;

        if (enumId && newEnumValues.length > 0) {
            await modelModule.createEnumValues({
                enum_id: enumId,
                values: newEnumValues,
            });
        }
        return enumId;
    }

    private openFactorNav(): void {
        if (this.sideTrigger != "settings") {
            this.sideTrigger = "settings";
        } else {
            this.sideTrigger = "sidebar-close";
        }
    }

    private async saveSettings(): Promise<void> {
        if (this.tabSettings && this.isEdit) {
            this.loading = true;
            await saveTab({
                tab: {
                    ...this.currentSettings,
                },
                new: false,
                factors: [],
                choices: [],
                viewpoints: [],
            });
            this.loading = false;
        } else {
            this.appCreateOpen = true;
        }
    }

    private async selectTabRootFactor(): Promise<void> {
        if (this.tabRootId != -1) {
            await modelModule.fetchFactor(this.tabRootId);
            this.toggleFactor({
                item: modelModule.factorMap[this.tabRootId],
                multiple: false,
                mobile: false,
            });
            this.open = { [this.tabRootId]: 0 };
            await modelModule.fetchChildFactors(this.tabRootId);
        } else {
            if (this.embed) {
                let rootFactor = this.factorTree[this.tabRootId][0];
                this.toggleFactor({
                    item: rootFactor,
                    multiple: false,
                    mobile: false,});
                this.open = { [rootFactor.id]: 0 };
                await modelModule.fetchChildFactors(rootFactor.id);
            }
        }
    }

    mounted(): void {
        this.onTabRootIdChange();
    }

    @Watch("isAdmin")
    private onChangeIsAdmin() {
        if (this.isAdmin) decisionsModule.selectDecision(-1);
        else if (decisionsModule.selectedDecisionId === -1) {
            decisionsModule.selectDecision(null);
        }
    }

    @Watch("selectedDecisionId")
    private onDecisionChange() {
        this.selectedIds = [];
        this.removeGroupFactorsIds = [];
        this.removeTableFactorsIds = [];
        this.removeColumnFactorsIds = [];
        this.removeFactorsIds = [];
    }

    @Watch("filterType")
    private onFilterTypeChange(val: string[]) {
        Vue.set(this.filters, "type", val);
    }

    @Watch("filterSearch")
    private onFilterSearchChange(val: string | null) {
        if (val) {
            Vue.set(this.filters, "search", val);
        } else {
            Vue.set(this.filters, "search", "");
        }
    }

    @Watch("tabRootId")
    private async onTabRootIdChange() {
        if (this.tabRootId != -1) {
            if (!modelModule.factorMap[this.tabRootId]) {
                await modelModule.fetchFactor(this.tabRootId);
            }
            this.selectedRootId = this.tabRootId;
        }
        if (this.embed) this.selectTabRootFactor();
    }
}
