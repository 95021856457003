
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    App,
    Tab,
    Decision,
    AppOptions,
} from "@/graphql/API";
import Apps from "@/store/modules/Apps";
import Decisions from "@/store/modules/Decisions";
import Choices from "@/store/modules/Choices";
import Viewpoints from "@/store/modules/Viewpoints";
import UsersModule from "@/store/modules/Users";
import Workspaces from "@/store/modules/Workspaces";
import { Urls } from "@/Urls";
/* Ui Components */
import AppInterface from "@/layouts/AppInterface.vue";
import Logo from "@/components/ui/Logo.vue";
/* End of Ui Components */
/* Tab Pages */
import TabLoading from "@/components/apps/tabs/TabLoading.vue";
import TabError from "@/components/apps/tabs/TabError.vue";
/* App Tab Components */
import AppDisplay from "@/components/apps/AppDisplay.vue";
import CompareView from "@/views/CompareView.vue";
import ChoiceEditor from "@/views/ChoiceEditor.vue";
import ScoreClassTable from "@/views/ScoreClassTable.vue";
import ChoicesView from "@/views/ChoicesView.vue";
import RankView from "@/views/RankView.vue";
import ModelView from "@/views/ModelView.vue";
import ViewpointsView from "@/views/ViewpointsView.vue";
import UnitView from "@/views/UnitView.vue";
import FactorAIView from "@/views/FactorAIView.vue";
import UsersView from "@/views/UsersView.vue";
import CommentsView from "@/views/CommentsView.vue";
import CommentsUsersView from "@/views/CommentsUsersView.vue";

const usersModule = getModule(UsersModule);
const appsModule = getModule(Apps);
const decisionsModule = getModule(Decisions);
const choiceModule = getModule(Choices);
const viewpointModule = getModule(Viewpoints);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {
        AppInterface,
        Logo,
        TabLoading,
        TabError,
        AppDisplay,
        CompareView,
        ChoiceEditor,
        RankView,
        ModelView,
        ChoicesView,
        ScoreClassTable,
        ViewpointsView,
        UnitView,
        UsersView,
        FactorAIView,
        CommentsView,
        CommentsUsersView,
    },
})
export default class AppLoadView extends Vue {
    @Prop()
    appId!: number;

    @Prop()
    tabId!: number;

    @Prop({ required: true })
    userId!: string;

    private selectedApp: App | null = null;
    private selectedDecision: Decision | null = null;
    private appLoading = false;
    private appError = false;

    private leftMenuOpen = true;
    private mobileOpen = false;

    private activeTabs: number[] = [];
    private tabLoading = false;

    get userEdit(): boolean {
        if (usersModule.currentPermissions) {
            return usersModule.currentPermissions.admin;
        } else {
            return false;
        }
    }

    get showTabEdit(): boolean {
        if (this.tabId && this.userEdit) {
            return true;
        } else {
            return false;
        }
    }

    get appOptions(): AppOptions {
        if (this.selectedApp && this.selectedApp.json.length) {
            return JSON.parse(this.selectedApp.json) as AppOptions;
        } else {
            return {};
        }
    }

    get editorLink(): string {
        return `${window.location.origin}${Urls.TABEDITOR}/${this.appId}/${this.tabId}`;
    }

    get pageTitle(): string {
        if (this.selectedApp) {
            if (this.selectedTab) {
                return `${this.selectedApp.title} | ${this.selectedTab.title}`;
            } else {
                return `${this.selectedApp.title}`;
            }
        } else {
            return process.env.VUE_APP_TITLE
                ? process.env.VUE_APP_TITLE
                : "Viewpoint AI";
        }
    }

    get tabIndex(): { [id: number]: Tab } | null {
        if (this.selectedApp) {
            return this.selectedApp.tabs.reduce((acc, a) => {
                return {
                    ...acc,
                    [a.id]: a,
                };
            }, {});
        } else {
            return null;
        }
    }

    get selectedTab(): Tab | null {
        if (this.tabIndex && this.tabId && this.tabIndex[this.tabId]) {
            return this.tabIndex[this.tabId];
        } else {
            return null;
        }
    }

    /* Pass whole index to this function too */
    getCompName(
        id: number,
        tabs: { [id: number]: Tab } | null,
        loading: boolean
    ): string {
        if (loading || !(tabs && tabs[id])) {
            return "TabLoading";
        } else if (tabs[id].type && tabs[id].type != "") {
            return tabs[id].type;
        } else {
            return "TabError";
        }
    }

    async createUserChoice(decision_id: number): Promise<void> {
        await choiceModule.createChoiceL({
            name: `${usersModule.currentUser?.first} ${usersModule.currentUser?.last}`,
            decision_id: decision_id,
        });
    }

    async createUserViewpoint(decision_id: number): Promise<void> {
        await viewpointModule.createViewpointL({
            name: `${usersModule.currentUser?.first} ${usersModule.currentUser?.last}`,
            decisionId: decision_id,
        });
    }

    async fetchUserChoices(decision_id: number): Promise<number> {
        const choices = await choiceModule.fetchChoices(decision_id)
        return choices.filter((choice) => {
            return choice.owner == this.userId;
        }).length;
    }

    async fetchUserViewpoints(decision_id: number): Promise<number> {
        const viewpoints = await viewpointModule.fetchViewpoints(decision_id);
        return viewpoints.filter((viewpoint) => {
            return viewpoint.owner == this.userId;
        }).length;
    }

    async checkChoices(decision_id: number, create: boolean): Promise<void> {
        if (create) {
            const num = await this.fetchUserChoices(decision_id);
            if (num == 0) {
                this.createUserChoice(decision_id);
            }
        }
    }

    async checkViewpoints(decision_id: number, create: boolean): Promise<void> {
        if (create) {
            const num = await this.fetchUserViewpoints(decision_id);
            if (num == 0) {
                this.createUserViewpoint(decision_id);
            }
        }
    }

    async loadApp(id: number): Promise<void> {
        this.appLoading = true;
        console.log(`Load App: ${id}`);
        this.selectedApp = await appsModule.getApp(id);
        if (this.selectedApp) {
            const appOptions = this.selectedApp.json
                ? (JSON.parse(this.selectedApp.json) as AppOptions)
                : {};
            this.selectedDecision = await decisionsModule.fetchDecision(
                this.selectedApp.decision_id
            );
            if (this.selectedDecision) {
                await Promise.all([
                    this.checkChoices(
                        this.selectedDecision.id,
                        appOptions.userChoice ? true : false
                    ),
                    this.checkViewpoints(
                        this.selectedDecision.id,
                        appOptions.userViewpoint ? true : false
                    ),
                ]);
                await workspaceModule.selectWorkspace(
                    this.selectedDecision.workspace_id
                );
                await decisionsModule.selectDecision(this.selectedDecision.id);
                const tabNum = this.selectedApp.tabs.length;
                console.log(`Tab NUm: ${tabNum}`);
                if (tabNum < 2) {
                    this.leftMenuOpen = false;
                }
                if (!this.tabId) {
                    if (tabNum) {
                        this.$router.push({
                            name: "AppLoadView",
                            params: {
                                appId: this.appId.toString(),
                                tabId: this.selectedApp.tabs[0].id.toString(),
                            },
                        });
                    }
                }
            }
        }
        if (!(this.selectedApp && this.selectedDecision)) {
            this.appError = true;
        }
        this.appLoading = false;
    }

    async mounted(): Promise<void> {
        this.onAppIdChange(this.appId);
        if (this.tabId) {
            this.onTabIdChange(this.tabId);
        }
    }

    @Watch("tabId")
    async onTabIdChange(id: number | null): Promise<void> {
        if (id) {
            this.tabLoading = true;
            if (!this.activeTabs.includes(id)) {
                this.activeTabs.push(id);
                await Promise.all([
                    appsModule.getTabFactors(id),
                    appsModule.getTabChoices(id),
                    appsModule.getTabViewpoints(id),
                ]);
            }
            this.tabLoading = false;
        }
    }

    @Watch("appId")
    async onAppIdChange(id: number): Promise<void> {
        if (id) {
            this.loadApp(id);
        }
    }

    @Watch("pageTitle")
    onPageTitleChange(val: string): void {
        document.title = val;
    }
}
