import { Factor, FactorAI, DecisionDefaults } from "@/graphql/API";
import { getModule } from "vuex-module-decorators";
import Scoring from "@/store/modules/Scoring";
import Decisions from "@/store/modules/Decisions";

const scoringModule = getModule(Scoring);
const decisionModule = getModule(Decisions);

export async function createFactorObj(
    factor: FactorAI,
    parent: number,
    decisionId: number,
    id: number,
    order: string,
    default_set: number | undefined
): Promise<Factor> {
    const newFactor: Factor = {
        id: id,
        name: factor.name,
        description: factor.description,
        parent_id: factor.parent_id ? factor.parent_id : parent,
        type: "",
        order_str: order,
        value_type: "",
        json: "",
        is_table: false,
        is_group: false,
        is_column: false,
        format: "",
        m_score: 0,
        m_index: undefined,
        weight: 1,
        index_rule_id: -2,
        score_rule_id: -1,
        set_id: default_set,
        enum_id: undefined,
        unit_id: undefined,
        decision_id: decisionId,
    };

    const factorType = factor.type.toLowerCase();

    const setDecisionDefaultRules = () => {
        const decisionDefaults: DecisionDefaults =
            decisionModule.allDecisionDefault;

        switch (newFactor.value_type) {
            case "number":
                newFactor.index_rule_id =
                    decisionDefaults[newFactor.value_type].index_rule_id;
                newFactor.score_rule_id =
                    decisionDefaults[newFactor.value_type].score_rule_id;
                newFactor.set_id =
                    decisionDefaults[newFactor.value_type].set_id;
                break;
            case "date_time":
                newFactor.index_rule_id =
                    decisionDefaults[newFactor.value_type].index_rule_id;
                newFactor.score_rule_id =
                    decisionDefaults[newFactor.value_type].score_rule_id;
                newFactor.set_id =
                    decisionDefaults[newFactor.value_type].set_id;
                break;
            case "category":
                newFactor.index_rule_id =
                    decisionDefaults[newFactor.value_type].index_rule_id;
                newFactor.score_rule_id =
                    decisionDefaults[newFactor.value_type].score_rule_id;
                newFactor.set_id =
                    decisionDefaults[newFactor.value_type].set_id;
                break;
            case "group":
                newFactor.index_rule_id =
                    decisionDefaults[newFactor.value_type].index_rule_id;
                newFactor.score_rule_id =
                    decisionDefaults[newFactor.value_type].score_rule_id;
                newFactor.set_id =
                    decisionDefaults[newFactor.value_type].set_id;
                break;
            case "table":
                newFactor.index_rule_id =
                    decisionDefaults[newFactor.value_type].index_rule_id;
                newFactor.score_rule_id =
                    decisionDefaults[newFactor.value_type].score_rule_id;
                newFactor.set_id =
                    decisionDefaults[newFactor.value_type].set_id;
                break;
            default:
                newFactor.index_rule_id =
                    decisionDefaults["default"].index_rule_id;
                newFactor.score_rule_id =
                    decisionDefaults["default"].score_rule_id;
                newFactor.set_id = decisionDefaults["default"].set_id;
        }
    };

    const setDefaultRule = () => {
        // we are using rule ids from decision with id:-2;
        const orgIdToDefaultRuleMap = scoringModule.defaultRules;
        switch (newFactor.value_type) {
            case "number":
                newFactor.index_rule_id = orgIdToDefaultRuleMap[-6].id;
                newFactor.score_rule_id = orgIdToDefaultRuleMap[-5].id;
                break;
            case "date_time":
                newFactor.index_rule_id = orgIdToDefaultRuleMap[-10].id;
                newFactor.score_rule_id = orgIdToDefaultRuleMap[-7].id;
                break;
            case "category":
                newFactor.index_rule_id = orgIdToDefaultRuleMap[-4].id;
                newFactor.score_rule_id = orgIdToDefaultRuleMap[-3].id;
                break;
            case "group":
                newFactor.index_rule_id = orgIdToDefaultRuleMap[-9].id;
                newFactor.score_rule_id = orgIdToDefaultRuleMap[-8].id;
                break;
            case "table":
                newFactor.index_rule_id = orgIdToDefaultRuleMap[-9].id;
                newFactor.score_rule_id = orgIdToDefaultRuleMap[-8].id;
                break;
            default:
                newFactor.index_rule_id = orgIdToDefaultRuleMap[-2].id;
                newFactor.score_rule_id = orgIdToDefaultRuleMap[-1].id;
        }
    };

    const setManualScore = () => {
        const falseTypes = [
            "group",
            "number",
            "date_time",
            "table",
            "category",
        ];
        if (falseTypes.includes(newFactor.value_type)) {
            newFactor.m_score = undefined;
            newFactor.m_index = undefined;
        } else {
            newFactor.m_score = 0;
            newFactor.m_index = undefined;
        }
    };

    const applyFactorValueTypeRules = () => {
        const decisionDefaults = decisionModule.allDecisionDefault;
        setManualScore();
        if (decisionDefaults && Object.keys(decisionDefaults).length > 0) {
            setDecisionDefaultRules();
        } else {
            setDefaultRule();
        }

        if (newFactor.value_type == "group") {
            newFactor.is_group = true;
        } else {
            newFactor.is_group = false;
        }

        if (newFactor.value_type === "table") {
            newFactor.is_table = true;
        } else {
            newFactor.is_table = false;
        }

        if (["string", "number"].includes(newFactor.value_type)) {
            newFactor.min = 0;
            newFactor.max = undefined;
        } else {
            newFactor.min = undefined;
            newFactor.max = undefined;
        }
    };

    switch (factorType) {
        case "text":
            newFactor.value_type = "string";
            newFactor.format = "rich";
            applyFactorValueTypeRules();
            break;
        case "number":
        case "numerical":
            newFactor.value_type = "number";
            applyFactorValueTypeRules();
            break;
        case "categorical":
        case "boolean":
        case "multiple choice":
            newFactor.value_type = "category";
            applyFactorValueTypeRules();
            break;
        case "group":
            newFactor.value_type = "group";
            applyFactorValueTypeRules();
            break;
        case "Document":
        case "document":
            newFactor.value_type = "document";
            applyFactorValueTypeRules();
            break;
        default:
            newFactor.value_type = "string";
            newFactor.format = "rich";
            applyFactorValueTypeRules();
    }

    return newFactor;
}
