
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor, Choice, Value } from "@/graphql/API";
import Choices from "@/store/modules/Choices";
import Comments from "@/store/modules/Comments";
import CommentCellBtn from "./CommentCellBtn.vue";
import CommentSideBar from "@/components/comments/tab/panel/CommentSideBar.vue";
import CommentOrder from "@/components/comments/tab/panel/CommentOrder.vue";
import FlashNotifications from "@/store/modules/FlashNotifications";

const choicesModule = getModule(Choices);
const commentModule = getModule(Comments);
const flashNotificationsModule = getModule(FlashNotifications);

@Component({
    components: {
        CommentCellBtn,
        CommentSideBar,
        CommentOrder,
    },
})
export default class CommentCell extends Vue {
    @Prop({ required: true })
    choice!: Choice;

    @Prop({ required: true })
    factor!: Factor;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    showTotal!: boolean;

    private open = false;
    private editorOpen = false;

    get value(): Value | null {
        if (
            choicesModule.choiceValues[this.choice.id] &&
            choicesModule.choiceValues[this.choice.id][this.factor.id]
        ) {
            return choicesModule.choiceValues[this.choice.id][this.factor.id];
        } else {
            return null;
        }
    }

    get commentTotal(): number {
        if (this.value) {
            return this.value.comments;
        } else {
            return 0;
        }
    }

    private toggleCell(val: boolean): void {
        this.open = val;
        if (!val) {
            commentModule.removeCell({
                factor_id: this.factor.id,
                choice_id: this.choice.id,
            });
        }
    }

    private selectComment(): void {
        this.$emit("comment-select", {
            factor: this.factor,
            choice: this.choice,
        });
    }

    private async createSummaryComment(): Promise<void> {
        let newComment = await commentModule.createSummaryComment({
            choice_id: this.choice.id,
            factor_id: this.factor.id,
            author: "bot",
        });
        if (newComment && newComment.id > 0) {
            await commentModule.fetchComments({
                choice_id: this.choice.id,
                factor_id: this.factor.id,
            });
            flashNotificationsModule.success({
                message: `The summary comment has been created successfully.`,
                duration: 5000,
            });
            this.open = true;
        } else {
            flashNotificationsModule.info({
                message: `We encountered an issue while saving your comment. 
                        Please try again. If the problem persists, contact support.`,
                duration: 5000,
            });
        }
    }
}
