
import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import FactorCreator from "@/components/model/FactorCreator.vue";
import FactorDisplay from "@/components/model/FactorDisplay.vue";
import { Factor, ModelFilters } from "@/graphql/API";
import ModelTableFactor from "@/components/model/ModelTableFactor.vue";
import RecrusiveModelFactor from "@/components/model/RecursiveModelFactor.vue";
import { FactorsIcons } from "@/IconLibrary";
import vuetify from "@/plugins/vuetify";
import draggable from "vuedraggable";
import {
    reorderFactors,
    updateOrderParentId,
    filterFactor,
    getAttributeFromJson,
} from "@/helpers/FactorHelper";

const modelModule = getModule(Factors);
@Component({
    name: "RecursiveModelGroup",
    components: {
        FactorCreator,
        FactorDisplay,
        ModelTableFactor,
        draggable,
        RecrusiveModelFactor,
    },
})
export default class RecursiveModelGroup extends Vue {
    @Prop({ default: () => [], type: Array })
    recursiveFactors!: Factor[];

    @Prop({ default: -1, type: Number })
    parentId!: number;

    @Prop({ default: -1, type: Number })
    hideDragId!: number;

    @Prop()
    rootFactorId!: number;

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop()
    selectedFactors!: number[];

    @Prop({ default: true, type: Boolean })
    editModel!: boolean;

    @Prop()
    filters!: ModelFilters;

    @Prop({ default: false, type: Boolean })
    parentLast!: boolean;

    @PropSync("loading")
    syncedLoading!: number[];

    @PropSync("open")
    syncedOpen!: any;

    @Prop({ default: () => [], type: Array })
    hiddenDepth!: number[];

    @Prop({ default: false, type: Boolean })
    isApp!: boolean;

    private drag = false;
    private readonly Icons: Record<string, string> = FactorsIcons;
    private currentTreeLevelChanged = false;

    get languageRight(): boolean {
        return vuetify.framework.rtl;
    }

    get factors(): Factor[] {
        return modelModule.factors;
    }

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get currentTreeLevel(): Factor[] {
        if (this.rootFactorId && this.rootFactorId != -1) {
            if (modelModule.factorMap[this.rootFactorId]) {
                return [modelModule.factorMap[this.rootFactorId]];
            } else {
                return [];
            }
        } else if (this.factorTree[this.parentId]) {
            return this.factorTree[this.parentId];
        } else return [];
    }

    get currentTreeLevelLength(): number {
        return this.currentTreeLevel.length;
    }

    private getFactorStyleClass(factor: Factor): string {
        if (factor.json && !factor.json.startsWith("v")) {
            return JSON.parse(factor.json).style_class
                ? JSON.parse(factor.json).style_class
                : "vp-body-1";
        }
        return "vp-body-1";
    }

    private async loadChildren(factorId: number) {
        if (factorId != null) {
            this.syncedLoading.push(factorId);
            await modelModule.fetchChildFactors(factorId);
            this.syncedLoading.splice(this.syncedLoading.indexOf(factorId), 1);
        }
    }

    private async deleteFactor(factor: Factor) {
        if (factor != null) {
            await modelModule.deleteFactorL([factor.id]);
            // await modelModule.deleteFactor(factor.id);
        }
    }

    private async deleteGroup(group: Factor) {
        if (group != null) {
            await modelModule.deleteGroupL([group.id]);
            // await modelModule.deleteGroup(group.id);
        }
    }

    private sendToggledFactor(event: {
        item: Factor;
        multiple: boolean;
        mobile: boolean;
    }) {
        if (event.item && (event.item.is_group || event.item.is_table)) {
            if (!this.syncedOpen[event.item.id]) {
                this.loadChildren(event.item.id);
                Vue.set(this.syncedOpen, event.item.id, 0);
            }
        }
        this.$emit("toggle-factor", event);
    }

    /* Eslint disabled for draggable event */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async dragChange(e: any, parentId: number) {
        //If factor moved in level - reorder factor within it's group
        if (e["moved"]) {
            const newOrderStr = reorderFactors(
                e.moved.element,
                parentId,
                e.moved.newIndex,
                e.moved.oldIndex,
                this.currentTreeLevel
            );
            if (newOrderStr)
                updateOrderParentId(
                    e.moved.element,
                    parentId,
                    newOrderStr,
                    false
                );
        }

        //Factor moved into different group- reorder factor within the new group
        if (e["added"]) {
            const newOrderStr = reorderFactors(
                e.added.element,
                parentId,
                e.added.newIndex,
                e.added.newIndex,
                this.currentTreeLevel
            );
            if (newOrderStr)
                updateOrderParentId(
                    e.added.element,
                    parentId,
                    newOrderStr,
                    true
                );
        }
    }

    private getIconFromType(typeId: number): string {
        const type = getModule(Factors).factorTypes[typeId];
        if (type) {
            const jsonOptions = type.json;
            return this.Icons[
                getAttributeFromJson(jsonOptions, "valueType", "string")
            ];
        }
        return this.Icons["string"];
    }

    @Watch("currentTreeLevel", { immediate: true })
    private onCurrentTreeLevel() {
        if (
            this.currentTreeLevel.length == 1 &&
            !this.currentTreeLevelChanged &&
            !this.isApp
        ) {
            if (
                this.currentTreeLevel[0] &&
                (this.currentTreeLevel[0].is_group ||
                    this.currentTreeLevel[0].is_table)
            ) {
                if (!this.syncedOpen[this.currentTreeLevel[0].id]) {
                    this.loadChildren(this.currentTreeLevel[0].id);
                    Vue.set(this.syncedOpen, this.currentTreeLevel[0].id, 0);
                }
            }
            this.currentTreeLevelChanged = true;
            this.$emit("toggle-factor", {
                item: this.currentTreeLevel[0],
                multiple: false,
            });
        }
    }
}
