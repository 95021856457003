
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UserPermission, Comment, Factor, Choice } from "@/graphql/API";
import CommentBlock from "@/components/comments/tab/panel/CommentItem.vue";
import { getModule } from "vuex-module-decorators";
import Comments from "@/store/modules/Comments";
import CommentOrder from "@/components/comments/tab/panel/CommentOrder.vue";
import FlashNotifications from "@/store/modules/FlashNotifications";

const commentModule = getModule(Comments);
const flashNotificationsModule = getModule(FlashNotifications);

@Component({
    components: {
        CommentBlock,
        CommentOrder,
    },
})
export default class CommentUserCell extends Vue {
    @Prop({ required: true })
    user!: UserPermission;

    @Prop({ default: () => [], type: Array })
    comments!: Comment[];

    @Prop({ default: () => [], type: Array })
    replies!: Comment[];

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ required: true })
    choice!: Choice;

    @Prop({ required: true })
    factor!: Factor;

    private open = false;
    private newCommentId = 0;

    get firstName(): string {
        return this.user.user.first ? this.user.user.first : "";
    }

    get lastName(): string {
        return this.user.user.last ? this.user.user.last : "";
    }

    get authorName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    get order(): "asc" | "desc" {
        return commentModule.order;
    }

    get userComments(): Comment[] {
        return this.comments.filter(
            (comment) => comment.author_name == this.authorName
        );
    }

    get userReplies(): Comment[] {
        return this.replies.filter(
            (comment) => comment.author_name == this.authorName
        );
    }

    get commentsOrdered(): Comment[] {
        return this.userComments.sort((a, b) => {
            if (this.order == "desc") {
                return a.created < b.created ? 1 : -1;
            } else {
                return a.created > b.created ? 1 : -1;
            }
        });
    }

    get repliesOrdered(): Comment[] {
        return this.userReplies.sort((a, b) => {
            if (this.order == "desc") {
                return a.created < b.created ? 1 : -1;
            } else {
                return a.created > b.created ? 1 : -1;
            }
        });
    }

    get showContent(): boolean {
        return this.userComments.length > 0 || this.userReplies.length > 0;
    }

    private async createUserSummaryComment(): Promise<void> {
        this.open = true;
        let newComment = await commentModule.createSummaryComment({
            choice_id: this.choice.id,
            factor_id: this.factor.id,
            author: this.user.user.id,
        });
        if (newComment && newComment.id > 0) {
            let getComments = await commentModule.fetchComments({
                choice_id: this.choice.id,
                factor_id: this.factor.id,
            });
            flashNotificationsModule.success({
                message: `The summary comment has been created successfully.`,
                duration: 5000,
            });

            if (getComments && newComment != null) {
                // Set new comment ID to trigger edit mode
                this.newCommentId = newComment.id;
            }
        } else {
            flashNotificationsModule.info({
                message: `We encountered an issue while saving your comment. 
                        Please try again. If the problem persists, contact support.`,
                duration: 5000,
            });
        }
    }
}
